import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { LoginModal } from './components/login-modal';
import { ForgotModal } from './components/forgot-modal';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { resetStateAction, setIdOfOpenModalAction } from './actions';

export const LoginWrapper = ({ onResetState, onSetIdOfOpenModal }) => {
	useInjectSaga({ key: 'loginSaga', saga });
	useInjectReducer({ key: 'loginReducer', reducer });

	const { search } = useLocation();

	const query = new URLSearchParams(search);

	useEffect(() => {
		if (query.get('modal') === 'forgot') {
			onSetIdOfOpenModal('forgot');
		}

		return () => onResetState();
	});

	return (
		<>
			<ForgotModal />
			<LoginModal />
		</>
	);
};

const mapDispatchToProps = {
	onResetState: resetStateAction,
	onSetIdOfOpenModal: setIdOfOpenModalAction,
};

const Login = connect(null, mapDispatchToProps)(LoginWrapper);

LoginWrapper.propTypes = {
	onResetState: PropTypes.func.isRequired,
	onSetIdOfOpenModal: PropTypes.func.isRequired,
};

export default Login;
