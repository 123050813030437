import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { valueToLink } from '../../containers/profile/utils';

const CustomModalCell = ({ rowData }) => {
	if (rowData.id === 'phone' || rowData.id === 'email') {
		return (
			<a className="custom-modal-cell__link" href={valueToLink(rowData.id, rowData.value)}>
				{rowData.value}
			</a>
		);
	}

	if (rowData.id?.includes('link') && rowData.value.target) {
		return (
			<a
				target={rowData.value.target}
				rel="noreferrer noopener"
				className="custom-modal-cell__link"
				href={rowData.value.url}
			>
				{rowData.value.text}
			</a>
		);
	}

	if (rowData.id?.includes('link') && !rowData.value.target) {
		return (
			<Link className="custom-modal-cell__link" to={rowData.value.url}>
				{rowData.value.text}
			</Link>
		);
	}

	if (rowData.id === 'company') {
		const { search, pathname } = useLocation();

		const query = new URLSearchParams(search);
		query.set('modal', 'company');
		query.set('id', rowData.value.id);

		return (
			<Link to={rowData.value.id ? `${pathname}?${query}` : '#'} className="custom-modal-cell__company">
				<b>{rowData.value.name}</b>
				<small>{rowData.value.role}</small>
			</Link>
		);
	}

	if (rowData.id === 'author') {
		return (
			<span className="custom-modal-cell__author">
				<b>{rowData.value.name}</b>
				<small>
					<i>{rowData.value.role}</i>
				</small>
			</span>
		);
	}

	if (rowData.id?.includes('temperatureMode')) {
		return (
			<span className="custom-modal-cell__temperature">
				<span>{rowData.value.name}</span>
				<div className="custom-modal-cell__temperature-content">
					<span>
						<b>Валидация: </b> {rowData.value.validation}
					</span>
					<span>
						<b>Действительна до: </b> {rowData.value.date}
					</span>
				</div>
			</span>
		);
	}

	if (rowData.id === 'contact') {
		return (
			<div className="custom-modal-cell__contact">
				<div className="custom-modal-cell__contact-name">
					{rowData.value.last_name} {rowData.value.first_name} {rowData.value.middle_name}
				</div>
				<div className="custom-modal-cell__contact-email">{rowData.value.email}</div>
				<div className="custom-modal-cell__contact-phone">
					{rowData.value.extension
						? `${rowData.value.phone}, ${rowData.value.extension}`
						: rowData.value.phone}
				</div>
			</div>
		);
	}

	if (rowData.id === 'address') {
		const { search, pathname } = useLocation();

		const query = new URLSearchParams(search);
		query.set('modal', 'address');
		query.set('addressId', rowData.value.id);

		// if address by law, not link
		if (rowData.value) {
			return rowData.value;
		}

		return (
			<Link to={rowData.value.id ? `${pathname}?${query}` : '#'} className="custom-modal-cell__link">
				{rowData.value.address}
			</Link>
		);
	}

	return rowData.value || '-';
};

CustomModalCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { CustomModalCell };
