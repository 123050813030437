/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, reduxForm, submit } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import { FORGOT_FORM_NAME, FORGOT_MODAL_STYLE } from '../../constants';
import { ForgotModalView } from './forgot-modal-view';
import { getIdOfOpenModalSelector, getIsForgotFormSubmittingSelector } from '../../selectors';
import { setIdOfOpenModalAction, submitForgotFormAction } from '../../actions';
import { forgotValidate } from '../../utils';

const ForgotModalWrapper = ({
	idOfOpenModal,
	onSetIdOfOpenModal,
	onFormSubmit,
	onSubmitForgotForm,
	isForgotFormSubmitting,
	onFieldChange,
}) => {
	const history = useHistory();

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	useEffect(() => {
		onFieldChange(FORGOT_FORM_NAME, 'email', query.get('email'));
	}, []);

	const handleForgotModalClose = () => {
		query.delete('modal');
		query.delete('email');

		history.push(`${pathname}?${query}`);

		onSetIdOfOpenModal('login');
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(FORGOT_FORM_NAME);
		onSubmitForgotForm();
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={idOfOpenModal === 'forgot'}
			onKeyDown={handleKeyDown}
			modalContent={
				<ForgotModalView
					onForgotModalClose={handleForgotModalClose}
					onFormSubmit={handleFormSubmit}
					isForgotFormSubmitting={isForgotFormSubmitting}
				/>
			}
			style={FORGOT_MODAL_STYLE}
		/>
	);
};

const withForm = reduxForm({
	form: FORGOT_FORM_NAME,
	enableReinitialize: true,
	onSubmit: forgotValidate,
})(ForgotModalWrapper);

const mapStateToProps = createStructuredSelector({
	idOfOpenModal: getIdOfOpenModalSelector(),
	isForgotFormSubmitting: getIsForgotFormSubmittingSelector(),
});

const mapDispatchToProps = {
	onFieldChange: change,
	onSetIdOfOpenModal: setIdOfOpenModalAction,
	onFormSubmit: submit,
	onSubmitForgotForm: submitForgotFormAction,
};

ForgotModalWrapper.propTypes = {
	onSetIdOfOpenModal: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onSubmitForgotForm: PropTypes.func.isRequired,
	isForgotFormSubmitting: PropTypes.bool.isRequired,
	idOfOpenModal: PropTypes.oneOf(['login', 'forgot']).isRequired,
	onFieldChange: PropTypes.func.isRequired,
};

const ForgotModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

export { ForgotModal };
