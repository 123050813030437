/* eslint-disable no-mixed-spaces-and-tabs */
import { SubmissionError } from 'redux-form';
import {
	ADDRESS_FORM_REQUIRED_FIELDS,
	AVIA_POINT_REQUIRED_FIELDS,
	AVIA_REQUEST_FORM_REQUIRED_FIELDS,
	AVIA_REQUEST_FORM_REQUIRED_FIELDS_NOT_PLACES,
	CAR_POINT_REQUIRED_FIELDS,
	CAR_REQUEST_FORM_REQUIRED_FIELDS,
	CONTACT_FORM_REQUIRED_FIELDS,
	FROM_POINT_REQUIRED_FIELDS,
	MY_CARRIER_ID,
	MY_CARRIER_REQUIRED_FIELDS,
	PARTNER_FORM_REQUIRED_FIELDS,
	POINT_REQUIRED_FIELDS,
	REQUEST_FORM_REQUIRED_FIELDS,
	SIZE_REQUIRED_FIELDS,
} from './constants';
import { goToElement } from '../../utils/goToElement';

export const transformToValidationErrors = errors => {
	goToElement(Object.keys(errors)[0]);
	return Object.entries(errors).reduce((prev, [key, error]) => {
		if (!prev[key]) {
			prev[key] = {};
		}

		if (key === 'mode_id') {
			prev.mode = error;
		}

		if (key === 'type_id') {
			prev.type = error;
		}

		if (key === 'rate_id') {
			prev.rate = error;
		}

		if (key.includes('size') && key !== 'size') {
			const arr = key.replace('_', '.').split('.');

			if (!prev.size) {
				prev.size = {};
			}

			prev.size = {
				...prev.size,
				[arr[1]]: {
					...(prev.size[arr[1]] || {}),
					[arr[2]]: !arr[3]
						? error
						: {
								id: error,
						  },
				},
			};
		}

		if (key.includes('points') && key !== 'points') {
			let arr = key.replace('_', '.').split('.');
			if (key === 'points.0.time_from') {
				arr = key.split('.');
			}

			if (!prev.points) {
				prev.points = {};
			}

			prev.points = {
				...prev.points,
				[arr[1]]: {
					...(prev.points[arr[1]] || {}),
					[arr[2]]: !arr[3]
						? error
						: {
								id: error,
						  },
				},
			};
		}

		prev[key] = error;

		return prev;
	}, {});
};

export const validatePartnerForm = values => {
	PARTNER_FORM_REQUIRED_FIELDS.forEach(key => {
		if (!values?.[key]) {
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});
};

export const validateContactForm = values => {
	CONTACT_FORM_REQUIRED_FIELDS.forEach(key => {
		if (!values?.[key] && key !== 'email') {
			goToElement(key);
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}

		if (key === 'email') {
			if (values[key] && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[key])) {
				throw new SubmissionError({ [key]: 'Не валидный email' });
			}
		}
	});
};

export const validateAddressModal = values => {
	ADDRESS_FORM_REQUIRED_FIELDS.forEach(key => {
		if (!values?.[key]) {
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});
};

const validateSize = fields => {
	fields?.forEach((item, idx) => {
		const error = { size: [] };

		SIZE_REQUIRED_FIELDS.forEach(key => {
			if (!item?.[key]) {
				if (!error?.size?.[idx]) {
					error.size[idx] = {};
				}

				error.size[idx][key] = 'Заполните это поле';
				goToElement(`size[${idx}].${key}`);
				throw new SubmissionError(error);
			}
		});
	});
};

const validatePointSize = (fields, index) => {
	fields?.forEach((item, idx) => {
		const error = { points: [] };

		error.points[index] = { size: [] };

		SIZE_REQUIRED_FIELDS.forEach(key => {
			if (!item?.[key]) {
				if (!error?.points?.[index].size?.[idx]) {
					error.points[index].size[idx] = {};
				}

				error.points[index].size[idx][key] = 'Заполните это поле';
				goToElement(`points[${index}].size[${idx}].${key}`);

				throw new SubmissionError(error);
			}
		});
	});
};

const validatePoint = (values, key, index, fields) => {
	fields.forEach(field => {
		if (field === 'size') {
			validatePointSize(values?.points?.[index]?.[field] || [], index);
		}

		if (!values?.points?.[index]?.[field]) {
			const error = { points: [] };

			if (
				field === 'date' ||
				field === 'time_from' ||
				field === 'time_to' ||
				field === 'weight' ||
				field === 'volume' ||
				field === 'place'
			) {
				error.points[index] = { [field]: 'Заполните это поле' };
				goToElement(`${key}.${field}`);
			} else {
				error.points[index] = { [field]: { id: 'Заполните это поле' } };
				goToElement(`${key}.${field}.id`);
			}
			throw new SubmissionError(error);
		}
	});
};

export const validateRequestForm = values => {
	const isAviaForm = window.location.pathname.includes('avia');

	const isCarForm = window.location.pathname.includes('car');

	let fields = REQUEST_FORM_REQUIRED_FIELDS;
	let pointFields = POINT_REQUIRED_FIELDS;

	if (isAviaForm && !values.is_to_places) {
		fields = AVIA_REQUEST_FORM_REQUIRED_FIELDS_NOT_PLACES;
		pointFields = AVIA_POINT_REQUIRED_FIELDS;
	} else if (isAviaForm) {
		fields = AVIA_REQUEST_FORM_REQUIRED_FIELDS;
		pointFields = AVIA_POINT_REQUIRED_FIELDS;
	} else if (isCarForm) {
		fields = CAR_REQUEST_FORM_REQUIRED_FIELDS;
		pointFields = CAR_POINT_REQUIRED_FIELDS;

		if (!values.place) {
			fields = fields.toSpliced(-1, 0, ...['weight', 'volume']);
			pointFields = [...pointFields, ...['weight', 'volume']];
		} else {
			fields = fields.toSpliced(-1, 0, ...['place']);
			pointFields = [...pointFields, ...['place']];
		}
	}

	if (values.tender_type_id === MY_CARRIER_ID) {
		fields = [...MY_CARRIER_REQUIRED_FIELDS, ...fields.slice(1)];
	}

	fields.forEach(key => {
		if (key === 'points.0') {
			validatePoint(values, 'points[0]', 0, FROM_POINT_REQUIRED_FIELDS);
		}

		if (key === 'size') {
			validateSize(values?.size);
		}

		if (key === 'options_points' && values?.points?.length > 1) {
			values?.points.forEach((_, idx) => {
				if (idx >= 1) {
					validatePoint(values, `points[${idx}]`, idx, pointFields);
				}
			});
		}

		if (!values?.[key] && !key.includes('points') && key !== 'options_points') {
			goToElement(key, 'req-form');
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});
};

export const normalizePhone = value => {
	if (!value) {
		return value;
	}

	if (value === '+') {
		return '';
	}

	const onlyNumbers = value.replace(/[^\d]/g, '');

	if (onlyNumbers[0] !== '7' && onlyNumbers.length === 1) {
		return `+7${onlyNumbers}`;
	}

	if (onlyNumbers.length <= 4) {
		return `+7${onlyNumbers.slice(1, 4)}`;
	}

	if (onlyNumbers.length <= 7) {
		return `+7(${onlyNumbers.slice(1, 4)})${onlyNumbers.slice(4)}`;
	}

	if (onlyNumbers.length <= 9) {
		return `+7(${onlyNumbers.slice(1, 4)})${onlyNumbers.slice(4, 7)}-${onlyNumbers.slice(7)}`;
	}

	return `+7(${onlyNumbers.slice(1, 4)})${onlyNumbers.slice(4, 7)}-${onlyNumbers.slice(7, 9)}-${onlyNumbers.slice(
		9,
		11,
	)}`;
};

export const deletePointInfo = (info, index) => {
	let count = 0;

	return Object.values(info).reduce((acc, item, i) => {
		if (i !== index) {
			acc[count] = item;
			count++;
		}

		return acc;
	}, {});
};
