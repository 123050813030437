/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { initialState } from './reducer';
import { DATE_FORMAT, DATE_TIME_FORMAT } from './constants';

export const selectCarrierChangeModalDomain = state => state.carrierChangeModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getReqActionsSelector = () =>
	createSelector(selectCarrierChangeModalDomain, ({ requestInfo }) => {
		return requestInfo?.actions || {};
	});

export const getIsCarriersLoadingSelector = () =>
	createSelector(
		selectCarrierChangeModalDomain,
		({ isCarriersLoading, isCarriersChanging }) => isCarriersLoading || isCarriersChanging,
	);

export const getCarriersInfoSelector = () =>
	createSelector(selectCarrierChangeModalDomain, getReqActionsSelector(), ({ carriers }, actions) => {
		return carriers.map(carrier => ({
			id: carrier.company.id,
			company: {
				name: carrier.company.name || '---',
				inn: carrier.company.inn || '---',
			},
			price: `${carrier.sum} руб`,
			info: carrier.info,
			status: carrier.status,
			date: moment(carrier.date).format(DATE_FORMAT),
			isActionButtonVisible: Boolean(actions.select),
		}));
	});

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'carrier_change');
	});

export const getIsModalApprovalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'carrier-approval');
	});

export const getReqNameSelector = () =>
	createSelector(selectCarrierChangeModalDomain, ({ requestInfo }) => {
		return `Заявка #${requestInfo?.id || '-'} от ${
			requestInfo?.created_at ? moment(requestInfo.created_at).format(DATE_TIME_FORMAT) : '-'
		}`;
	});

export const getReqAdditionalInfoSelector = () =>
	createSelector(selectCarrierChangeModalDomain, ({ requestInfo }) => {
		const date = requestInfo?.points.at(-1)?.date;

		return `Дата доставки по заявке: ${date ? moment(date).format(DATE_FORMAT) : '-'}`;
	});

export const getModalStatusesSelector = () =>
	createSelector(selectCarrierChangeModalDomain, ({ requestInfo }) => {
		const collectStatuses = [];

		if (requestInfo?.tab && !isEmpty(requestInfo?.tab)) {
			collectStatuses.push(requestInfo?.tab);
		}
		if (requestInfo?.status && !isEmpty(requestInfo?.status)) {
			collectStatuses.push(requestInfo?.status);
		}

		return collectStatuses;
	});

export const getIsRefusalButtonVisibleSelector = () =>
	createSelector(getReqActionsSelector(), actions => {
		return Boolean(actions.rejects);
	});
