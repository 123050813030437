/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { destroy, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import {
	getActiveRelationshipIdSelector,
	getRelationshipInfoSelector,
	getRelationshipNameSelector,
	getIsRelationshipAddFormOpenSelector,
	getIsRelationshipEditFormOpenSelector,
	getIsRelationshipInfoLoadingSelector,
	getIsModalShownSelector,
	getIsAuditTabShownSelector,
} from './selectors';
import {
	createRelationshipAction,
	deleteRelationshipAction,
	getRelationshipInfoAction,
	resetStateAction,
	updateRelationshipAction,
	getCarriersListAction,
	getShippersListAction,
} from './actions';
import { RelationshipContent } from './components/relationship-content';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getIsAvailableRelationshipButtonsSelector } from '../profile/selectors';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';

const RelationshipModalWrapper = ({
	onGetRelationshipInfo,
	isRelationshipInfoLoading,
	isRelationshipEditFormOpen,
	isRelationshipAddFormOpen,
	relationshipInfo,
	relationshipName,
	onResetState,
	onDeleteRelationship,
	isModalShown,
	activeRelationshipId,
	onCreateRelationship,
	onUpdateRelationship,
	onGetCarriersList,
	isAvailable,
	onFormSubmit,
	onSetWindowInfo,
	onResetForm,
	isAuditTabShown,
	onGetShippersList,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'relationshipModalSaga', saga });
	useInjectReducer({ key: 'relationshipModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (activeRelationshipId) {
			onGetRelationshipInfo(activeRelationshipId);
		}
	}, [activeRelationshipId]);

	useEffect(() => {
		if (isRelationshipEditFormOpen || isRelationshipAddFormOpen) {
			onGetShippersList();
			onGetCarriersList();
		}
	}, [isRelationshipEditFormOpen, isRelationshipAddFormOpen]);

	const handleRelationshipFormOpenButtonClick = mode => {
		if (activeRelationshipId) {
			query.set('id', activeRelationshipId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			onResetForm(FORM_NAME);
			query.delete('mode');
		}

		if (mode === 'cancel' && !activeRelationshipId) {
			onResetState();
			onResetForm(FORM_NAME);
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleRelationshipDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteRelationship({ id: activeRelationshipId, redirect: handleModalClose }),
			},
		});
	};

	const handleRelationshipFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		if (activeRelationshipId) {
			onUpdateRelationship({
				id: activeRelationshipId,
				redirect: () => handleRelationshipFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		onCreateRelationship({
			redirect: handleModalClose,
			resetState: () => {
				onResetState();
				onResetForm(FORM_NAME);
			},
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleRelationshipFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isRelationshipEditFormOpen || isRelationshipAddFormOpen
					? `${isRelationshipEditFormOpen ? 'Изменить ТЭО' : 'Создать ТЭО'}`
					: relationshipName
			}
			navItems={
				isRelationshipAddFormOpen || isRelationshipEditFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<RelationshipContent
						relationshipInfo={relationshipInfo}
						isRelationshipInfoLoading={isRelationshipInfoLoading}
						isRelationshipFormOpen={isRelationshipEditFormOpen || isRelationshipAddFormOpen}
					/>
				) : (
					<Audit entityName="company_relationship" entityId={activeRelationshipId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'relationship_modal' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isRelationshipEditFormOpen || isRelationshipAddFormOpen) &&
						isAvailable.delete &&
						!isAuditTabShown
							? [
									{
										onClick: handleRelationshipDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isRelationshipInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isRelationshipEditFormOpen || isRelationshipAddFormOpen
							? [
									{
										onClick: handleRelationshipFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isRelationshipInfoLoading,
									},
									{
										onClick: () => handleRelationshipFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isRelationshipInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handleRelationshipFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isRelationshipInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	relationshipInfo: getRelationshipInfoSelector(),
	isRelationshipInfoLoading: getIsRelationshipInfoLoadingSelector(),
	isRelationshipAddFormOpen: getIsRelationshipAddFormOpenSelector(),
	isRelationshipEditFormOpen: getIsRelationshipEditFormOpenSelector(),
	relationshipName: getRelationshipNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeRelationshipId: getActiveRelationshipIdSelector(),
	isAvailable: getIsAvailableRelationshipButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetRelationshipInfo: getRelationshipInfoAction,
	onDeleteRelationship: deleteRelationshipAction,
	onGetShippersList: getShippersListAction,
	onResetState: resetStateAction,
	onCreateRelationship: createRelationshipAction,
	onUpdateRelationship: updateRelationshipAction,
	onGetCarriersList: getCarriersListAction,
	onFormSubmit: submit,
	onSetWindowInfo: setWindowInfoAction,
	onResetForm: destroy,
};

const RelationshipModal = connect(mapStateToProps, mapDispatchToProps)(RelationshipModalWrapper);

RelationshipModalWrapper.propTypes = {
	onGetRelationshipInfo: PropTypes.func.isRequired,
	relationshipInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}),
	),
	isRelationshipInfoLoading: PropTypes.bool.isRequired,
	isRelationshipEditFormOpen: PropTypes.bool.isRequired,
	isRelationshipAddFormOpen: PropTypes.bool.isRequired,
	relationshipName: PropTypes.string.isRequired,
	onDeleteRelationship: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeRelationshipId: PropTypes.string,
	onCreateRelationship: PropTypes.func.isRequired,
	onUpdateRelationship: PropTypes.func.isRequired,
	onGetCarriersList: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onFormSubmit: PropTypes.func.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	onResetForm: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	onGetShippersList: PropTypes.func.isRequired,
};

export default RelationshipModal;
