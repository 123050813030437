export const goToElement = (el, form = null) => {
	if (form) {
		document.querySelector(`[class="${form}"]`).querySelector(`[name="${el}"]`)?.scrollIntoView({
			block: 'center',
			inline: 'center',
			behavior: 'smooth',
		});
	} else {
		document.querySelector(`[name="${el}"]`)?.scrollIntoView({
			block: 'center',
			inline: 'center',
			behavior: 'smooth',
		});
	}
};
