import { call, put } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { getLocalStorageItem } from '../utils/localStorage';
import { setWindowInfoAction } from '../containers/alert-modal/actions';
import { notificationInit } from '../modules/notifications/actions';
import { DISMISS_TIME, UNKNOWN_ERROR_MESSAGE, UNKNOWN_ERROR_TITLE } from './constants';

export const getToken = () => getLocalStorageItem('user');
export const getUser = () => getLocalStorageItem('user_data');

export function* makeRequest(url, options, parameters) {
	try {
		if (!navigator.onLine) {
			yield put(
				setWindowInfoAction({
					type: 'error',
					title: 'Нет подключения к интернету',
					text: 'Проверьте подключение и обновить страницу',
				}),
			);

			return { message: 'Нет подключения к интернету' };
		}

		const response = yield call(fetch, parameters ? `${url}?${new URLSearchParams(parameters)}` : url, {
			...options,
			headers: {
				...options.headers,
				'X-socket-Id': window.Echo ? window.Echo.connector.pusher.connection.socket_id : '',
			},
		});

		if (response?.status === 401 && window.location.pathname !== '/login') {
			yield put(
				setWindowInfoAction({ type: 'logout', title: 'Авторизация истекла', text: 'Войдите в систему заново' }),
			);

			return { message: 'Авторизация истекла' };
		}

		if (response?.status === 402) {
			if (getUser().company_type === 'carrier') {
				yield put(
					setWindowInfoAction({
						type: 'forbidden',
						title: 'Внимание!',
						text: 'Пройдите валидацию и получите полный доступ к функционалу',
						phone: '+7 (495) 136-22-00',
						email: 'hello@mak-logic.ru',
					}),
				);
			}

			if (getUser().company_type === 'shipper') {
				yield put(
					setWindowInfoAction({
						type: 'forbidden',
						title: 'Внимание!',
						text: 'Заявка закрыта. Для полного доступа к функционалу подпишите договор с МАК-Лоджик',
						phone: '+7 (495) 136-22-00',
						email: 'hello@mak-logic.ru',
					}),
				);
			}

			return { message: 'Нет права доступа' };
		}

		if (response?.status === 413) {
			yield put(
				setWindowInfoAction({
					type: 'forbidden',
					title: 'Превышены лимиты',
					text: 'Общее количество загруженных файлов не должно превышать 20 файлов.',
				}),
			);

			return { message: 'Превышены лимиты' };
		}

		if (response?.status === 403) {
			yield put(
				setWindowInfoAction({
					type: 'forbidden',
					title: 'Нет права доступа',
					text: 'Обратитесь к администратору',
				}),
			);

			return { message: 'Нет права доступа' };
		}

		if ([400, 404, 500].includes(response.status)) {
			yield put(
				setWindowInfoAction({
					type: 'error',
					title: 'Что то пошло не так...',
					text: 'Попробуйте обновить страницу или обратиться к администратору',
				}),
			);

			return { message: 'Что то пошло не так...' };
		}

		if (!response) {
			yield put(
				setWindowInfoAction({
					type: 'error',
					title: 'Система недоступна',
					text: 'Попробуйте обновить страницу или обратиться к администратору',
				}),
			);

			return { message: 'Система недоступна' };
		}

		const json = yield response.json();

		return json;
	} catch (error) {
		if (error.message === 'Load failed') {
			yield put(
				setWindowInfoAction({
					type: 'forbidden',
					title: 'Превышены лимиты',
					text: 'Общее количество загруженных файлов не должно превышать 20 файлов.',
				}),
			);

			return { message: 'Превышены лимиты' };
		}

		yield put(
			setWindowInfoAction({
				type: 'error',
				title: 'Система недоступна',
				text: 'Попробуйте обновить страницу или обратиться к администратору',
			}),
		);

		return { message: 'Система недоступна' };
	}
}

export function* errorHandler(error) {
	yield put(
		notificationInit({
			id: uuidv4(),
			message: error?.message || UNKNOWN_ERROR_MESSAGE,
			title: UNKNOWN_ERROR_TITLE,
			type: 'warning',
			dismissAfter: DISMISS_TIME,
		}),
	);
}
