import {
	RESET_STATE,
	SET_CARS,
	SET_IS_CARS_LOADING,
	SET_IS_CAR_CHANGING,
	SET_IS_CAR_SAVING,
	SET_IS_REQUEST_INFO_LOADING,
	SET_IS_TONNAGES_LOADING,
	SET_IS_TRAILERS_LIST_LOADING,
	SET_IS_TRAILER_SAVING,
	SET_REQUEST_INFO,
	SET_TONNAGES,
	SET_TRAILERS,
	SET_TRAILERS_LIST,
	SET_DRIVERS,
} from './actions';

export const initialState = {
	isCarChanging: false,
	isCarsLoading: false,
	isRequestInfoLoading: false,
	requestInfo: null,
	cars: [],
	trailers: [],
	isCarSaving: false,
	trailersList: [],
	isTrailersListLoading: false,
	isTrailerSaving: false,
	isTonnagesLoading: false,
	tonnages: [],
	drivers: [],
};

const carChangeModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_CAR_CHANGING:
			return {
				...state,
				isCarChanging: payload,
			};
		case SET_IS_REQUEST_INFO_LOADING:
			return {
				...state,
				isRequestInfoLoading: payload,
			};
		case SET_REQUEST_INFO:
			return {
				...state,
				requestInfo: payload,
			};
		case SET_CARS:
			return {
				...state,
				cars: payload,
			};
		case SET_IS_CARS_LOADING:
			return {
				...state,
				isCarsLoading: payload,
			};
		case SET_IS_CAR_SAVING:
			return {
				...state,
				isCarSaving: payload,
			};
		case SET_TRAILERS_LIST:
			return {
				...state,
				trailersList: payload,
			};
		case SET_IS_TRAILERS_LIST_LOADING:
			return {
				...state,
				isTrailersListLoading: payload,
			};
		case SET_IS_TRAILER_SAVING:
			return {
				...state,
				isTrailerSaving: payload,
			};
		case SET_TRAILERS:
			return {
				...state,
				trailers: payload,
			};
		case SET_TONNAGES:
			return {
				...state,
				tonnages: payload,
			};
		case SET_IS_TONNAGES_LOADING:
			return {
				...state,
				isTonnagesLoading: payload,
			};
		case RESET_STATE:
			return {
				...initialState,
			};
		case SET_DRIVERS:
			return {
				...state,
				drivers: payload,
			};
		default:
			return state;
	}
};

export default carChangeModalReducer;
