import { CustomCell } from './components/custom-claim-cell/index';

export const TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '100px', customComponent: CustomCell },
	{ name: 'Дата', id: 'created_at', width: '150px', formatter: value => new Date(value).toLocaleDateString('ru-RU') },
	{ name: 'Заявка', id: 'request_id', width: '120px', customComponent: CustomCell },
	{ name: 'Раздел', id: 'request_category', width: '200px' },
	{ name: 'Категория', id: 'category_name', width: '200px' },
	{ name: 'От кого', id: 'from_name', width: '200px' },
	{ name: 'К кому', id: 'to_name', width: '200px' },
	{ name: 'Приоритет', id: 'priority_name', width: '150px' },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABS = [
	{ key: 'opened', text: 'Новые', href: '/claims/opened' },
	{ key: 'approve', text: 'Необходимо согласовать', href: '/claims/approve' },
	{ key: 'approval', text: 'На согласовании', href: '/claims/approval' },
	{ key: 'closed', text: 'Завершенные', href: '/claims/closed' },
];
